<template>
  <v-container>
    <v-layout text-center wrap>
      <v-flex xs12 mb-4>
        <GoogleSignin></GoogleSignin>
        <p v-if="authErrors.length > 0"
          >There was an error logging in to your account.</p
        >
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import GoogleSignin from '@components/google-signin'
import {authComputed} from '@state/helpers'
import {mapState} from 'vuex'
import appConfig from '@src/app.config'
export default {
  page: {
    title: 'Log in',
    meta: [{name: 'description', content: `Log in to ${appConfig.title}`}],
  },
  components: {
    GoogleSignin,
  },
  data: () => ({}),
  computed: {
    ...authComputed,
  },
  watch: {
    isLoggedIn(nval, oval) {
      if (nval) {
        if (this.$route.query != {} && this.$route.query.redirectFrom) {
          this.$router.push(this.$route.query.redirectFrom)
        }
      }
    },
  },
}
</script>
<style scoped>
</style>

<style lang="scss" module>
//@import '@design';
Layout {
  text-align: center;
}
</style>
