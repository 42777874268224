import { render, staticRenderFns } from "./google-login.vue?vue&type=template&id=5f2e8ddd&scoped=true&"
import script from "./google-login.vue?vue&type=script&lang=js&"
export * from "./google-login.vue?vue&type=script&lang=js&"
import style1 from "./google-login.vue?vue&type=style&index=1&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5f2e8ddd",
  null
  
)

export default component.exports